import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import {
  useLocation
} from 'react-router-dom'

import { StateController } from './../../App'

import VerticalCenter from '../VerticalCenter/VerticalCenter'
import Container from '../Container/Container'
import Icon from '../../components/atoms/Icon'
import Profile from '../../components/atoms/Profile'
import { sendDataLayer } from '../../utils/functions'

function Header({
  page,
  headerType,
  logo,
  items,
  submenu,
  loggedIn,
  logOutAction
}) {
  const { context } = useContext(StateController)
  const location = useLocation()
  const [showOthers, setShowOthers] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const isSubLevel = (children) => {
    const page = location.pathname.replace('/', '')
    const data = children.find((item) => item.page === page)

    return data ? true : false
  }

  const togglerMenu = (e) => {    
    setShowOthers(!showOthers)
    sendDataLayer({ datalayer: { event: "menu", text: e.target.textContent, link: e.target.textContent  === 'Términos y condiciones' ? '/terminos-y-condiciones' : e.target.textContent  === 'Preguntas frecuentes' ? '/preguntas-frecuentes': e.target.baseURI }, repeat: true })

  }

  const toogleSumMenu = (e) => {
    setShowMenu(!showMenu)
    sendDataLayer({ datalayer: { event: "menu", text: e.target.textContent, link: e.target.baseURI }, repeat: true })

  }

  const closeSumMenu = () => {
    setShowMenu(false)
  }

  const addDataLayer = (e) => {
    sendDataLayer({ datalayer: { event: "menu", text: e.target.textContent, link: e.target.baseURI }, repeat: true })

  }

  useEffect(() => {
    const child = items.find((item) => item.sublevel)

    if (child) {
      setHasChildren(child)
    }
  }, [items])

  useEffect(() => {

    window.addEventListener('click', ({ target }) => {
      const window = target.closest('.mc-header-container__nav--extra')
      const button = target.closest('.mc-header-container__nav--button')

      if (window === null && button === null) setShowOthers(false)
    })
  }, [])

  const closeSession = (e) => {
    logOutAction()
    setShowOthers(false)
    sendDataLayer({ datalayer: { event: "menu", text: e.target.textContent, link: e.target.baseURI }, repeat: true })

  }

  return (
    <div
      className={`
      mc-header-container mc-header-container-type-${headerType} 
      mc-header-container-page-${page !== '' ? page ? page : 'login' : 'login'} 
      ${showMenu ? 'show-menu' : ''}`
      }>
      <div className='mc-header-container__menu'>
        <Container className={'custom-header'} fullHeight={true}>

          <Link className="mc-header-container__logo" to={loggedIn ? '/premio-mayor' : '/'}>
            <VerticalCenter>
              <img src={`/assets/images/${logo}`} alt="Logo Banco de Bogotá" />
            </VerticalCenter>
          </Link>

          <div className={`mc-header-container__logo logoMc ${!loggedIn ? 'showLogo' : ''}`}>
            <VerticalCenter>
              <img src={`/assets/images/logo-mastercard.png`} alt="" />
            </VerticalCenter>
          </div>



          {headerType === 4 &&
            <div className="mc-header-container__mobile--submenu" onClick={toogleSumMenu}>
              <VerticalCenter>
                <Icon name="menu" />
              </VerticalCenter>
            </div>
          }

          {
            loggedIn &&

            <nav className="mc-header-container__nav">
            {headerType === 4 &&
              <div
                className="mc-header-container__mobile--overlay"
                onClick={closeSumMenu}
              >
              </div>
            }
            {headerType === 4 &&
              <div className="mc-header-container__nav--close" onClick={closeSumMenu}>
                <Icon name="close" />
              </div>
            }

            {loggedIn
              ? <ul>
                {items.filter((item) => {
                  if (item?.component?.props?.privatePage || item?.sublevel) {
                    return true
                  } else {
                    return false
                  }
                }).map((item, index) => {
                  return (
                    item.sublevel
                      ? <li
                        className={`mc-header-container__nav--item slow_ani ${item.page} ${((showOthers) || (isSubLevel(item.children))) ? 'mc-header-container__nav--item-active' : ''}`}
                        onClick={togglerMenu}
                        key={index}
                      >
                        <div className="mc-header-container__nav--button">
                          <VerticalCenter>
                            <span className='header-items'>
                              {item.icon &&
                                <Icon name={item.icon} />
                              }
                              <u>{item.title}</u>
                            </span>
                          </VerticalCenter>
                        </div>
                      </li>
                      : <li
                        className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/', '') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`}
                        onClick={addDataLayer}
                        key={index}
                      >
                        <Link to={`/${item.page}`}>
                          <VerticalCenter>
                            <span className='header-items'>
                              {item.icon &&
                                <Icon name={item.icon} />
                              }
                              <u>{item.title}</u>
                            </span>
                          </VerticalCenter>
                        </Link>
                      </li>
                  )
                })}
                <li className={`mc-header-container__nav--item slow_ani mc-header-container__nav--item-active`} onClick={closeSession}>
                  <VerticalCenter>
                    <div className="closeSession">
                      <span className='header-items'>
                        <Icon name="close_session" />
                        <u style={{ marginLeft: '12px' }}>Cerrar sesión</u>
                      </span>
                    </div>
                  </VerticalCenter>
                </li>
                <li className={`mc-header-container__nav--item slow_ani mc-header-container__nav--item-active`}>
                  <VerticalCenter>
                    <div className="">
                      <span className='header-items logo_mc'>
                        <img src="/assets/images/logo-mastercard.png" alt="" />
                      </span>
                    </div>
                  </VerticalCenter>
                </li>
              </ul>
              : <ul>

                {items.filter((item) => {
                  if (item?.component?.props?.privatePage || item?.sublevel) {
                    return false
                  } else {
                    return true
                  }
                }).map((item, index) => {
                  return (
                    <li
                      className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/', '') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`}
                      onClick={addDataLayer}
                      key={index}
                    >
                      <Link to={`/${item.page}`}>
                        <VerticalCenter>
                          <span>
                            {item.icon &&
                              <Icon name={item.icon} />
                            }
                            {item.title}
                          </span>
                        </VerticalCenter>
                      </Link>
                    </li>
                  )
                })}

                <VerticalCenter>
                  <div className="">
                    <span className='header-items logo_mc'>
                      <img src="/assets/images/logo-mastercard.png" alt="" />
                    </span>
                  </div>
                </VerticalCenter>
              </ul>
            }

            {hasChildren &&
              <div className={`mc-header-container__nav--extra slow_ani ${showOthers ? 'opened' : ''}`}>
                {hasChildren.children.map((item, index) => {
                  return (
                    <Link to={`/${item.page}`} key={index} onClick={togglerMenu}>
                      <span>
                        {item.icon &&
                          <Icon name={item.icon} />
                        }
                        {item.title}
                      </span>
                    </Link>
                  )
                })}
                <div className="mc-header-container__nav--close" onClick={closeSession}>
                  <Icon name='close_session_red' /> Cerrar sesión
                </div>
              </div>
            }
          </nav>
          }
        </Container>
      </div>

      {submenu &&
        <div className={`mc-header-container__submenu ${!loggedIn ? 'not-login' : ''}`}>
          <Container fullHeight={true}>
            <VerticalCenter>
              {submenu.type === 'labels' &&
                <>
                  {submenu.icon && <Icon name={submenu.icon} />}
                  <div dangerouslySetInnerHTML={{ __html: submenu.content }} />
                </>
              }
              {submenu.type === 'nickname' && loggedIn && context?.user?.nickname &&
                <Profile
                  nickname={context.user.nickname}
                  defaultLetter={submenu.default}
                />
              }
            </VerticalCenter>
          </Container>
        </div>
      }
    </div>
  )
}

export default Header