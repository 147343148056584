import React from 'react'
import Slider from "react-slick"
import Icon from "../atoms/Icon";
import CardBox from '../molecules/CardBox'

function SliderRotator({
  mobileItemsToShow,
  desktopItemsToShow,
  list,
  className,
  centerMode,
  rows,
  slidesPerRow,
}) {

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return <button className="custom-next-arrow" onClick={onClick}><Icon name='nextArrow' /></button>;
  };
  
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return <button className="custom-prev-arrow" onClick={onClick}><Icon name='prevArrow' /></button>;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: desktopItemsToShow,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    centerPadding: '40px 0px 0px',
    className: centerMode ? "center" : '',
    centerMode: centerMode,
    initialSlide: 1, 
    pauseOnHover: true,
    focusOnSelect: true,
    nextArrow: <CustomNextArrow />, 
    prevArrow: <CustomPrevArrow />,
    rows: rows ? rows : 1,
    slidesPerRow: slidesPerRow ? slidesPerRow : 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {                    
          infinite: true,
          dots: true,          
          autoplay: true,     
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: mobileItemsToShow,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: false,
          autoplay: true,
          rows:1
        },
      }
    ]
  }

  return (
    <div className={`mc-slider-rotator`}>
      <Slider {...settings}>
        {list.map((slide, index) => {

          return (
            <div key={index} className='mc-slider-rotator__slide'>
              <CardBox
                image={slide.image}
                imgHover={slide.imgHover}
                title={slide.title}
                desc={slide.desc}
                className={className}
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default SliderRotator