import React, { useEffect } from 'react'
import Columns, { Col } from '../../layouts/Columns/Columns'
import Title from '../atoms/Title'
import Button from '../atoms/Button'
import Container from '../../layouts/Container/Container';
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter';
function NotFound({loggedIn}) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  return (
    <section className='mc-page not-found slow_ani'>
      <Container>
        <Columns desktop={2} mobile={1}>
        <Col>
          <Title 
            text={<>Parece que nos desviamos y no encontramos esta página</>}
            tag={'h1'}
            className={'size-40 title-notFound'}
          />

          <Button 
            type={'primary'}
            status={'active'}
            text={'Volver al home'}
            link={loggedIn ? '/premio-mayor' : '/'}
            className={'fit-content button-notFound-desk'}
          />
        </Col>
        <Col>
          <VerticalCenter>
            <figure>
              <img className='img-Error' src="/assets/images/Error-404.png" alt="Imagen de error 404" />
            </figure>
          </VerticalCenter>
          <Button 
            type={'primary'}
            text={'Volver al home'}
            link={'/'}
            className={'fit-content button-notFound-mobile'}
          />
        </Col>
        </Columns>
      </Container>
    </section>
  )
}
export default NotFound