import React from 'react'
import Title from '../atoms/Title'
import Columns, { Col } from '../../layouts/Columns/Columns'

const SectionChooseWinner = ({ title, description, image }) => {
    return (
        <div className='SectionChooseWinner'>
            <article>
                <Title
                    text={<>{title}</>}
                    tag={'h2'}
                    className={'text-center'}
                />

            </article>
            <article>
                <div className="wrap-section">
                    <Columns desktop={2} mobile={1}>
                        <Col>

                            <div className="description size-20" dangerouslySetInnerHTML={{ __html: description }} >

                            </div>                            
                        </Col>
                        <Col>

                            <div className="img-section">
                                <img src={`/assets/images/${image}`} alt="" />
                            </div>
                        </Col>
                    </Columns >
                </div>


            </article >
        </div>
    )
}

export default SectionChooseWinner
