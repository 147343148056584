import React from 'react'

const Title = ({ text,tag, className }) => {

    if (tag === 'h1') {
        return (
            <h1 className={`Title-custom-${tag} ${className ? className : ''}`}>
                {text ? text : <>Texto aqui...</>}
            </h1>
        )

    }
    else if (tag === 'h2') {
        return (
            <h2 className={`Title-custom-${tag} ${className ? className : ''}`}>
                {text ? text : <>Texto aqui...</>}
            </h2>
        )
    }
    else if (tag === 'h3') {
        return (
            <h3 className={`Title-custom-${tag} ${className ? className : ''}`}>
                {text ? text : <>Texto aqui...</>}
            </h3>
        )
    }
    else if (tag === 'h4') {
        return (
            <h4 className={`Title-custom-${tag} ${className ? className : ''}`}>
                {text ? text : <>Texto aqui...</>}
            </h4>
        )
    }
    else if (tag === 'h5') {
        return (
            <h5 className={`Title-custom-${tag} ${className ? className : ''}`}>
                {text ? text : <>Texto aqui...</>}
            </h5>
        )
    }
    else if (tag === 'h6') {
        return (
            <h6 className={`Title-custom-${tag} ${className ? className : ''}`}>
                {text ? text : <>Texto aqui...</>}
            </h6>
        )
    }
}

export default Title
