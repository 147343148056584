import React, { useEffect, useContext, useState } from 'react'
import { Link } from "react-router-dom"
import { StateController } from './../../App'

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Input from '../atoms/Input'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import FormQuantumData from './FormQuantumData'
import { sendDataLayer } from '../../utils/functions'
import Title from '../atoms/Title'

function CustomModal({
  token,
  show,
  setShowModal,
  primaryAction,
  secondaryAction,
  terms,
  image,
  price,
  name,
  cta,
  twoStepAuth,
  location,
  brand_id,
  quantumName,
  setQuantumName,
  quantumDoc,
  setQuantumDoc,
  department,
  city,
  site,
  setDepartment,
  setCity,
  setSite,
  redemtion,
  awardSelected,
  showSuccessRedemtion,
  desc,
  title
}) {
  const { setMdalOpen } = useContext(StateController)  
  const [birthdate, setBirthdate] = useState('')

  useEffect(() => {

    setMdalOpen(show ? true : false)
  }, [show])

  const close = () => {
    if (redemtion) {
      sendDataLayer({ datalayer: { event: 'cancel_bond', product_name: awardSelected?.name, product_price: awardSelected?.price, index: awardSelected?.index }, repeat: true })
    }
    setShowModal(false)
    setBirthdate('')
  }

  const handleChangeDate = async (e) => {
    setBirthdate(e.target.value)
    
    if (primaryAction.setAuthDate) {
      primaryAction.setAuthDate(e.target.value)
    }
  }

  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani`}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className={`mc-custom-modal__center ${redemtion ? 'modalRedeem' : ''} ${showSuccessRedemtion ? 'info_modals' : ''}`}>
        <VerticalCenter>
          <div className="mc-custom-modal__center--window slow_ani">


            {
              redemtion ?

              <>
                <div className="mc-custom-modal__close" onClick={close}>
                  <Icon name="close_modal" />
                </div>

                <div className="modal_header">
                  {image &&
                    <div
                      className="mc-custom-modal__center--window__image"
                      style={{ "backgroundImage": `url(/assets/images/galeria/${image})` }}
                    >
                    </div>
                  }
                  <div className="content_modal_header">
                    <div className="mc-custom-modal__center--window__desc">
                      <div className='name' dangerouslySetInnerHTML={{ __html: name }} />
                      <div className='desc-name'>Bono por:</div>
                    </div>
                    <div className="mc-custom-modal__center--window__title">{price}</div>
                  </div>
                </div>

                <div className="modal_body">

                  {!twoStepAuth &&
                    <div className="mc-custom-modal__center--window__auth">
                      <Title 
                        text={<>Validación de seguridad</>}
                        tag={'h4'}
                        className={'font-extra-bold size-16 my-0 mb-2'}
                      />
                      <p className='desc_auth font-thin'>Para descargar tu bono digital, valida tu identidad:</p>

                      <Input
                        type={'birthdate'}
                        label={'Fecha de expedición de su cédula'}
                        placeholder={'dd/mm/aaaa'}
                        onChange={handleChangeDate}
                        inputByCharacter={false}
                        maxlength={10}
                        value={birthdate}
                        error={false}
                        className='lbl_twoStepAuth'
                      />
                    </div>
                  }

                  {
                    location ?
                      <FormQuantumData
                        token={token}
                        location={location}
                        brand_id={brand_id}
                        quantumName={quantumName}
                        setQuantumName={setQuantumName}
                        quantumDoc={quantumDoc}
                        setQuantumDoc={setQuantumDoc}
                        department={department}
                        city={city}
                        site={site}
                        setDepartment={setDepartment}
                        setCity={setCity}
                        setSite={setSite}
                      />
                      :
                      <></>
                  }
                </div>

                <div className="mc-custom-modal__center--window__actions">
                  <Button
                    type="primary"
                    text={primaryAction ? primaryAction.buttonText : cta}
                    loading={primaryAction ? primaryAction.loading : false}
                    status={birthdate !== '' ? 'active' : 'inactive' }
                    onClick={primaryAction ? primaryAction.action : close}
                    className={'px-3'}
                    icon={birthdate !== '' ? '' :'secure'}
                  />
                  {secondaryAction &&
                    <div onClick={close}>
                      <Button
                      type="cancel"
                      text={secondaryAction.buttonText}
                      loading={false}
                      status="active"
                      onClick={secondaryAction.action}
                      className={'fit-content'}
                    />
                    </div>
                  }
                </div>
                {terms &&
                  <div className="mc-custom-modal__center--window__terms">
                    <Link to={terms.termsLink} target="_blank">
                      {terms.termsText}
                    </Link>
                  </div>
                }

                {
                  <p className='size-12'>Los bonos digitales están sujetos a términos y condiciones de cada comercio aliado, los cuales se especifican en el bono digital que se redime y descarga en la plataforma.</p>
                }
              </>
              :
              <>
                <div className="mc-custom-modal__close" onClick={close}>
                  <Icon name="close_modal" />
                </div>

                <div className="modal_header">
                  {image &&
                    <div
                      className="mc-custom-modal__center--window__image"
                      style={{ "backgroundImage": `url(/assets/images/icons/${image})` }}
                    >
                    </div>
                  }
                  <div className="content_modal_header">
                    <div className="mc-custom-modal__center--window__desc">
                      <div className='name font-extra-bold size-24' dangerouslySetInnerHTML={{ __html: title }} />                      
                    </div>
                  </div>
                </div>

                <div className="modal_body">
                    <div className="size-22 text-center font-thin">{desc}</div>


                </div>

                <div className="mc-custom-modal__center--window__actions">
                  <Button
                    type="primary"
                    text={primaryAction ? primaryAction.buttonText : cta}
                    loading={primaryAction ? primaryAction.loading : false}
                    status={'active'}
                    onClick={primaryAction ? primaryAction.action : close}
                    className={'px-3'}
                  />
                </div>
                {terms &&
                  <div className="mc-custom-modal__center--window__terms">
                    <Link to={terms.termsLink} target="_blank">
                      {terms.termsText}
                    </Link>
                  </div>
                }
              </>
            }


              
            

          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default CustomModal