import React, { useRef, useState, } from 'react'
import Container from '../../layouts/Container/Container'
import Columns, { Col } from '../../layouts/Columns/Columns'
import Title from '../atoms/Title'
import Button from '../atoms/Button'
import { sendDataLayer } from '../../utils/functions'


const SectionSpecialAward = () => {
  const articleRef = useRef(null);
  const [positions, setPositions] = useState([]);

  const datalayerJackpot = (e) => {
    sendDataLayer({ datalayer: { event: "go_jackpot", text: e.target.textContent, link: window?.location?.href }, repeat: true })
  }

  const handleMouseMove = (e) => {
    const rect = articleRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    articleRef.current.style.setProperty('--x', x + 'px')
    articleRef.current.style.setProperty('--y', y + 'px')
    
    setPositions((prev) => [
      ...prev,
      { x: x, y: y, id: Math.random() },
    ]);

    
    if (positions.length > 30) {
      setPositions((prev) => prev.slice(1));
    }

  };

  return (
    <section className='mc-page SectionSpecialAward'>
      <Container>
        <Columns desktop={2} mobile={1}>
          <Col>
            <article className='title-specialAward'>
              <Title
                text={<>Participa en el sorteo por un Citroën C-Elysée 0 km</>}
                tag={'h2'}
                className={'text-sm-start text-center mb-4 mt-2'}
              />
              <div onClick={datalayerJackpot}>
                <Button
                  type={'secondary'}
                  text={<>¿Cómo ganar este premio?</>}
                  loading={''}
                  status={'active'}
                  link={'/premio-mayor'}
                  className={'w-75'}
                />
              </div>
            </article>
          </Col>
          <Col>
            <article
              ref={articleRef}
              onMouseMove={handleMouseMove}
              className='art-img-special-award'>

              <picture>
                <source srcSet="/assets/images/BN-img-banner-hero2-mobile.png" media="(max-width: 768px)" />
                <img
                  src="/assets/images/BN-img-banner-hero2.png"
                  alt="Luz verde para ganar"
                />
              </picture>
              {positions.map((pos) => (
                <div

                  key={pos.id}
                  className="circle"          
                />
              ))}
            </article>
          </Col>
        </Columns>
      </Container>
    </section>
  )
}

export default SectionSpecialAward
