import { getUser, getStaticContent } from "../services/user.services"
import { 
  getDashboard, 
  saveTerms,
  saveFaqs
} from "../services/dashboard.services"
import { isLoggedIn } from "../services/auth.services.js"
import { redeem, downloadRedeem, getDepartments, getCities, getSites } from "../services/redemptions.services.js"


export const isLog = async function(token) {
  return new Promise((resolve) => {

    isLoggedIn({ token: token }).then((res) => {
      resolve(res)
    }).catch(error => {
      resolve(error)
    })
  })
}

export const gtUser = async function(token) {
  return new Promise((resolve) => {

    getUser({ token: token }).then((user) => {

      resolve(user)
    }).catch(error => {
      resolve(error)
    })
  })
}

export const gtStaticContent = async() => {
  return new Promise((resolve) => {

    getStaticContent().then((content) => {

      resolve(content)
    }).catch(error => {
      resolve(error)
    })
  })
}

export const doRedeem = async function(token, price, id_award, authDate) {
  return new Promise((resolve) => {

    redeem({ token, price, id_award, authDate}).then((data) => {      

      if (data && data.status === 200) {
        const file = data.file ? data.file : data.data.file
        const mime = data.headers ? data.headers['Content-Type'] : data.data.headers['Content-Type']
        const ext = mime.includes('pdf') ? 'pdf' : 'zip'
        const name = `boleta.${ext}`
        var a = document.createElement("a")
        a.href = file.includes('data') ? file : `data:${mime};base64,${file}`
        
        a.download = name
        a.click()

        resolve({
          status: 200,
          file: name,
          data: data.detail
        })

      } else {
        resolve({
          status: 400,
          data: data.detail
        })
      }

    }).catch(error => {
      resolve(error)
    })
  })
}

export const doloadRedeem = async function(id_redemtion, token) {
  return new Promise((resolve) => {
    downloadRedeem({ token: token, id: id_redemtion }).then((data) => {
      if (data && data.status === 200) {
        const file = data.data.file

        if (data.data.headers) {
          const mime = data.data.headers['Content-Type']
          const ext = mime.includes('pdf') ? 'pdf' : 'zip'
          const name = `boleta.${ext}`
          var a = document.createElement("a")
          a.href = file.includes('data') ? file : `data:${mime};base64,${file}`
          a.download = name
          a.click()
  
          resolve({
            status: 200,
            file: name,
            data: data.detail
          })
        } else {
          resolve({
            status: 400,
            data: 'Bono no encontrado'
          })
        }

      } else {
        resolve({
          status: 400,
          data: data.detail
        })
      }
    }).catch(error => {
      resolve(error)
    })
  })
}

export const gtDashboard = async function(code) {
  return new Promise((resolve) => {

    getDashboard({ code: code }).then((data) => {

      resolve(data)
    }).catch(error => {
      resolve(error)
    })
  })
}

export const svTerms = async function(code, data) {
  return new Promise((resolve) => {

    saveTerms({ code: code, data: data }).then((data) => {

      resolve(data)
    }).catch(error => {
      resolve(error)
    })
  })
}

export const svFaqs = async function(code, data) {
  return new Promise((resolve) => {

    saveFaqs({ code: code, data: data }).then((data) => {

      resolve(data)
    }).catch(error => {
      resolve(error)
    })
  })
}

export const gtDepartments = async function(token, brand_id) {
  return new Promise((resolve) => {

    getDepartments({ token: token, brand_id: brand_id}).then((data) => {

      resolve(data)
    }).catch(error => {
      resolve(error)
    })
  })
}

export const gtCities = async function(token, brand_id, department_id) {
  return new Promise((resolve) => {

    getCities({ token: token, brand_id: brand_id, department_id: department_id}).then((data) => {

      resolve(data)
    }).catch(error => {
      resolve(error)
    })
  })
}

export const gtSities = async function(token, brand_id, department_id, city_id) {
  return new Promise((resolve) => {

    getSites({ token: token, brand_id: brand_id, department_id: department_id, city_id: city_id}).then((data) => {

      resolve(data)
    }).catch(error => {
      resolve(error)
    })
  })
}
