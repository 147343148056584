import React from 'react'
import Button from '../atoms/Button'
import Title from '../atoms/Title'
import Container from '../../layouts/Container/Container'
import { sendDataLayer } from '../../utils/functions'

const SectionTyc = () => {
  const datalayerGotTyc = (e) =>{
    sendDataLayer({datalayer:{event: "go_tyc", text: e.target.textContent, link: window?.location?.href}, repeat: true})
  }
  return (
    <section className='mc-page sectionTyc'>
      <Container>
        <article>
          <div>
            <Title
              tag={'h2'}
              text={<>Términos y condiciones</>}
              className={'text-center'}
            />
            <div className='text-center' onClick={datalayerGotTyc}>
              <Button
                type={'auxiliar'}
                text={<>Conocer más</>}
                loading={''}
                status={'active'}
                link={'/terminos-y-condiciones'}
                className={'fit-content'}
              />
            </div>
          </div>
        </article>
      </Container>
    </section>
  )
}

export default SectionTyc
