import React from 'react'
import Container from '../../layouts/Container/Container'

function PrimaryBanner({
  children,
  mainTitle,
  description,
  className,
}) {

  return (
    <div className={`mc-banner-container ${className ? className : ''}`}>
      <Container>
        <div className="imgTitle">
          <img src="/assets/images/luz-verde.png" alt="" />
        </div>

        {
          mainTitle &&
          <div className={`mainTitle ${description ? 'mb-0': ''}`}>
            {mainTitle}
          </div>
        }

        {
          description &&
          <div className="description">
            <p>{description}</p>
          </div>
        }

        {
          children && <></>
        }
      </Container>
    </div>
  )
}

export default PrimaryBanner