import React, { useContext, useEffect, useState } from 'react'
import { StateController } from '../../App'

const AwardStock = () => {
  const { context } = useContext(StateController)
  const [stockAwards, setStockAwards] = useState([]);
  const [award, setAward] = useState(0);
  
  useEffect(() => {
    if (context) {
      setStockAwards(context?.stock)
      setAward(context?.user[`award_${context?.block}`])
    }

  }, [context])

  const stock_filtered = stockAwards?.filter(item => {
    if (!(item.Total === item.Consumidos)) {
        return item.Valor === award
    }
  })
  
  const totalBonos = stock_filtered.reduce((acc, premio) => {
    return acc + premio.Total;
  }, 0)  
  const bonosRestantes = stock_filtered.reduce((acc, premio) => {
    return acc + premio.Restantes;
  }, 0)  

  return (
    <div className='awardStock'>
      <div className="data_info">
        <span className="title">Bonos totales</span>
        <span className="value_stock-1">{totalBonos ? totalBonos : 0}</span>
      </div>
      <div className="data_info">
        <span className="title">Bonos restantes</span>
        <span className="value_stock-2">{bonosRestantes}</span>
      </div>
      <div className="data_info">
        <span className="title">Valor del bono</span>
        <span className="value_stock-3">US ${award}</span>
      </div>
    </div>
  )
}

export default AwardStock
