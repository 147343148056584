import React, { useEffect } from 'react'
import PrimaryBanner from '../molecules/PrimaryBanner'
import Title from '../atoms/Title'
import SliderRotator from '../organisms/SliderRotator'
import Container from '../../layouts/Container/Container'
import SectionChooseWinner from '../organisms/SectionChooseWinner'
import SectionTyc from '../organisms/SectionTyc'

const PremioMayor = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  return (
    <>
      <section className='mc-page PremioMayor slow_ani' >
        <article>
          <PrimaryBanner
          className={'PremioMayor'}
            mainTitle={<>
              <h2 className='size-58 size-58-lg-26'><span>Gana un Citroën</span> <br /> <span className='size-53 size-53-lg-26'>C-Elysée 0 kilómetros</span></h2>
            </>
            }
            description={<>El auto de tus sueños está más cerca de lo que crees, prepárate <br /> <span className='bg-text'>para disfrutar de la comodidad que te mereces.</span></>}
          />
        </article>
      </section>

      <section className='mc-page sectionCarEquipment'>
        <Container>

          <article>
            <Title
              className={'text-center'}
              tag={'h2'}
              text={<>¿Con qué está equipado?</>}
            />
          </article>

          <article>
            <SliderRotator
              mobileItemsToShow={1}
              desktopItemsToShow={2}
              centerMode={true}
              rows={2}
              slidesPerRow={1}
              list={[
                {
                  id: 1,
                  title: 'Vidrios eléctricos delanteros y traseros.',
                  image: 'que-incluye-1.png'
                },
                {
                  id: 2,
                  title: 'Cuatro airbags, luces LED diurnas, y faros antiniebla.',
                  image: 'que-incluye-3.png'
                },
                {
                  id: 3,
                  title: 'Rendimiento de motor de hasta 97 km/galón.',
                  image: 'que-incluye-2.png'
                },
                {
                  id: 4,
                  title: 'Amplio maletero con 506 litros de capacidad, y un cómodo interior.',
                  image: 'que-incluye-4.png'
                },
              ]}
              className={'car_equipment'}
            />
          </article>
        </Container>
      </section>

      <section className='mc-page section_Choose_Winner'>
        <Container>
            <SectionChooseWinner
              title={<>¿Cómo ganar?</>}
              description={'El afortunado dueño de este auto será elegido en un sorteo entre quienes hayan acumulado más oportunidades para ganar y redimido al menos un bono digital.'}
              image={'banner_section_Choose_Winner.png'}
            />
        </Container>
      </section>

      <SectionTyc />
    </>
  )
}

export default PremioMayor
