import React, { useContext, useEffect } from 'react'

import Container from '../../layouts/Container/Container'
import PrimaryBanner from '../molecules/PrimaryBanner'
import FaqsFeed from '../organisms/FaqsFeed'
import { StateController } from '../../App'
import Preloader from '../atoms/Preloader'

function Faqs() {
  const { staticContent } = useContext(StateController)
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page faqs slow_ani'>

      <PrimaryBanner
        className={'terms_faqrs'}
        image="banner_mini.png"
        mainTitle={<><h1 className='size-78 size-78-lg-37 text-start color-white'>Preguntas <br /> frecuentes</h1></>}
      />

      <Container>

      {staticContent
          ? <FaqsFeed
              faqsType={1}
              list={staticContent.faqs}
            />
          : <Preloader
              type="fullscreen"
              invert
            />
        }
      </Container>

    </div>
  )
}
export default Faqs