import React from 'react'
import { Link } from "react-router-dom"

import Container from '../Container/Container'
import { sendDataLayer } from '../../utils/functions'

function Footer({
  footerType,
  footerCardsImg,
  secondaryFooterImg,
  links,
  linksText,
  content,
  copyright
}) {

  return (
    <div className={`mc-footer-container mc-footer-container-type-${footerType}`}>

      {footerType === 1 &&
        <div className='mc-footer-container__bg'>
          <Container>
            <div>
              {secondaryFooterImg &&
                <img
                  src={`/assets/images/${secondaryFooterImg}`}
                  alt="Footer"
                  className='mc-footer-container__image'
                />
              }
            </div>
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />          

            <div>
              {footerCardsImg &&
                <img
                  src={`/assets/images/${footerCardsImg}`}
                  alt="Footer"
                  className='mc-footer-container__image'
                />

              }
            </div>

            <div className='mc-footer-container__section'>
              {links && links[0] &&
                <ul className='mc-footer-container__section--links'>
                  {links.map((link, index) => {
                    return (
                      <Link
                        onClick={(e) => {                          
                          sendDataLayer({ datalayer: { event: "footer", text: e.target.textContent, link: link.link }, repeat: true })
                        }}
                        key={index}
                        to={link.link}
                      >{link.text}</Link>
                    )
                  })}
                </ul>
              }
              {linksText && <p>{linksText}</p>}
            </div>

            {
              copyright &&
              <p className='text-center'>{copyright}</p>
            }
          </Container>
        </div>
      }

    </div>
  )
}

export default Footer