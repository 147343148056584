import React, { useState } from 'react'
import AwardsGallery from '../organisms/AwardsGallery';
import Preloader from '../atoms/Preloader';

const Tabs = ({
    awards,
    canRedem,
    redemtions,
    // setTabSelected
}) => {
    const [activeTab, setActiveTab] = useState(1)

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
        // setTabSelected(tabNumber)
    };

    return (
        <div className='tabs'>
            <div className='tab-buttons'>
                <button className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabChange(1)}>Tus bonos</button>
                <button className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabChange(2)}>Bonos redimidos</button>
            </div>
            <div className={`tab-content`}>
                {activeTab === 1 && <TabContent1 awards={awards} canRedem={canRedem} />}
                {activeTab === 2 && <TabContent2 redemtions={redemtions} />}
            </div>
        </div>
    );
};

const TabContent1 = ({awards, canRedem}) => {
    return <>
        {awards
            ? <AwardsGallery
                awardBoxType={1}
                itemsPerLine={4}
                limit={false}
                limitButtonText="Ver más comercios"
                showStock={true}
                list={awards}
                steps={false}
                canRedem={canRedem}
                twoStepAuth={false}
                redemtionText="Redimir"
                // termText="Términos y condiciones"
                // termLink="/terminos-y-condiciones"
            />
            : <Preloader
                type="fullscreen"
                invert
            />
        }
    </>;
};

const TabContent2 = ({redemtions}) => {
    return <>
    
    {redemtions
          ? <AwardsGallery
              awardBoxType={1}
              itemsPerLine={3}
              list={redemtions}
              showStock={false}
              canRedem={true}
              download={true}
              redemtionText="Descargar bono"
            //   termText="Términos y condiciones"
            //   termLink="/terminos-y-condiciones"
            />
          : <Preloader
              type="fullscreen"
              invert
            />
        }

    </>;
};

export default Tabs
