import React, { useState } from 'react'
import Title from '../atoms/Title'

function CardBox({
  image,
  title,
  desc,
  className,
  imgHover,
}) {
  const [imageSrc, setImageSrc] = useState(image);
  const handleMouseOver = () => {
    if (imgHover) {
      setImageSrc(imgHover)
         
    }
  };

  const handleMouseOut = () => {
    if (imgHover) {
    setImageSrc(image)
    }
  };

  return (
    <div onMouseOver={handleMouseOver } onMouseOut={handleMouseOut}  className={`mc-cardbox-container ${className ? className : ''}`}>
      {image &&
        <img src={`/assets/images/${imgHover ? imageSrc : image}`} alt={`${title}`} />
      }
      <div className="content-card">
        {title &&
          <Title
            text={title}
            tag={'h3'}
            className={'text-start mb-2 w-100'}

          />
        }
        {desc &&
          <div dangerouslySetInnerHTML={{ __html: desc }} />
        }
      </div>
    </div>
  )
}

export default CardBox