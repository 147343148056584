import React, { useContext, useEffect, useState } from 'react'
import Container from '../../layouts/Container/Container'
import ProgressBar from '../atoms/ProgressBar'
import Columns, { Col } from '../../layouts/Columns/Columns'
import Title from '../atoms/Title'
import Icon from '../atoms/Icon'
import Button from '../atoms/Button'
import SectionSpecialAward from '../organisms/SectionSpecialAward'
import SectionTyc from '../organisms/SectionTyc'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import { StateController } from '../../App'
import Preloader from '../atoms/Preloader'
import { sendDataLayer } from '../../utils/functions'

function Progress() {
  const { context } = useContext(StateController)
  const [porcentaje, setPorcentaje] = useState(0);
  const [imageSrc, setImageSrc] = useState('banner_progress_off.png');
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const percAmount =
      (
        context.tracing ? ((context?.tracing?.amount_1 / context?.user?.goal_amount_1) * 100) : 0
      ).toFixed(1)
    let perc = percAmount > 100 ? 100 : percAmount
    setPorcentaje(perc)
  }, [context?.tracing?.amount_1, context?.user?.goal_amount_1]);

  const handleMouseOver = () => {
    setImageSrc('banner_progress_on.png');
  };

  const handleMouseOut = () => {
    setImageSrc('banner_progress_off.png');
  };

  const dataLayerProgreso = (e)=>{
    sendDataLayer({datalayer: {event: "progress", text: e.target.textContent, link: window?.location?.href}, repeat:true})
  }
  return (
    <>
      {
        context ?
          <section className='mc-page progress slow_ani my-5' >
            <Container>
              <article>
                <Title
                  text={<>Conoce tu meta y sigue tu progreso</>}
                  tag={'h1'}
                  className={'text-center mb-4 size-32 font-extra-bold'}
                />
              </article>

            </Container>
            <Container>
              <Columns desktop={2} mobile={1}>
                <Col>
                  <article>
                    <div className="wrap-progress">

                      <Title
                        text={<>Así va tu meta de facturación</>}
                        tag={'h4'}
                        className={'text-center mb-4'}
                      />

                      <div className="info-progress">
                        <div className="current-progress">
                          <p>Llevas</p>
                          <p><strong>{context?.tracing[`amount_${context?.block}`]} USD</strong></p>
                        </div>
                        <div className="goal-progress">
                          <p>Te falta</p>
                          <p><strong>{context?.user[`goal_amount_${context?.block}`]} USD</strong></p>
                        </div>
                      </div>

                      <div className="stoplight">
                        <span className='mySpan'><Icon name={(porcentaje > 0) ? 'stoplight_on' : 'stoplight_off'} /></span>
                        <span className='mySpan'><Icon name={(porcentaje >= 50) ? 'stoplight_on' : 'stoplight_off'} /></span>
                        <span className='mySpan'><Icon name={(porcentaje >= 100) ? 'stoplight_on' : 'stoplight_off'} /></span>
                      </div>

                      <div className="progress_bar">
                        <ProgressBar
                          percent={porcentaje}
                          label={`${porcentaje}%`}

                        />
                      </div>

                      <div className="data_info">
                        <hr />
                        <Title
                          text={<>Última actualización {context && context?.tracing?.date_update}</>}
                          tag={'h4'}
                          className={'text-center mb-4 size-20 size-20-sm-14 mt-3'}
                        />
                        <p className='copy-1'>Con tu progreso tienes <span className='style-100'><VerticalCenter>100</VerticalCenter></span> oportunidades para ganar.</p>
                          <p className='copy-3 font-extra-bold pb-3'><strong>Cumple tu meta antes del 11 de noviembre de 2024</strong></p>
                        <p className='copy-2'>Una vez cumplas tu meta podrás canjear  tu premio.</p>

                        <div className="btn_premios fit-content" onClick={dataLayerProgreso}>
                          <Button
                            type={'primary'}
                            text='Ver premios'
                            status={'active'}
                            link={'/premios'}
                            className={'fit-content'}
                          />
                        </div>

                        
                      </div>



                    </div>
                  </article>

                </Col>

                <Col>
                  <article>
                    <div onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut} className="banner_progress">
                      <img

                        src={`/assets/images/${imageSrc}`} alt="" />
                    </div>
                  </article>
                </Col>
              </Columns>
            </Container>
          </section>
          :
          <Preloader type={'fullscreen'} />
      }

      <SectionSpecialAward />
      <SectionTyc />




    </>

  )
}
export default Progress