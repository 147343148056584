import React, { useEffect, useState, useContext } from 'react'
import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import PrimaryBanner from '../molecules/PrimaryBanner'
import Title from '../atoms/Title'
import Preloader from '../atoms/Preloader'

function Terms() {
  const { staticContent } = useContext(StateController)
  const [termsHTML, setTermsHTML] = useState(false)  

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (staticContent && staticContent.terms && staticContent.terms[0].content) {
      setTermsHTML(staticContent.terms[0].content)
    }
  }, [staticContent])

  return (
    <div className='mc-page terms slow_ani'>

      <PrimaryBanner
      className={'terms_faqrs'}
        image="banner_mini.png"
        mainTitle={<><h1 className='size-78 size-78-lg-37 text-start color-white'>Términos <br /> y condiciones</h1></>}
      />

      <section className=''>
        <Container>
          <article>            
            <Title tag={'h4'} text={<>TÉRMINOS Y CONDICIONES <br /> CAMPAÑA “LUZ VERDE PARA GANAR”</>} className={'text-center size-24 mt72'} />

          </article>

          <article className='px-2'>

            {termsHTML ?
              <div className='mc-static-content'>
                <div dangerouslySetInnerHTML={{ __html: termsHTML }} />
              </div>

              :
              <>

                <Preloader type={'fullscreen'} />

              </>
            }
          </article>
        </Container>
      </section>

    </div >
  )
}
export default Terms